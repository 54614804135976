import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";

import { SectionHeading } from "../heading/sectionHeading";
import { ProductCard } from "../card/productCard";
import { PickupList } from "../../utils/pickupList";

export const PickupWrapper = () => {
  return (
    <SWrapper className="pickupWrapper">
      <SectionHeading heading="PICKUP" />
      <SGrid>
        {PickupList.map(({ href, itemName, price, img }) => {
          return (
            <ProductCard
              href={href}
              itemName={itemName}
              price={price}
              img={img}
              key={itemName}
            />
          );
        })}
      </SGrid>
    </SWrapper>
  );
};

const SWrapper = styled.section`
  border-top: 1px solid ${Color.border};
  padding: 2rem 0;
`;

const SGrid = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
  gap: 32px 16px;
`;
