import ImgProduct001 from "../images/product_001.jpg";
import ImgProduct002 from "../images/product_002.jpg";
import ImgProduct003 from "../images/product_003.jpg";
import ImgProduct004 from "../images/product_004.jpg";

export const PickupList = [
  {
    href: "/product",
    itemName: "ITEM001",
    tag: "hardware",
    price: "10,000",
    img: ImgProduct001,
  },
  {
    href: "/product",
    itemName: "ITEM002",
    tag: "hardware",
    price: "10,000",
    img: ImgProduct002,
  },
  {
    href: "/product",
    itemName: "ITEM003",
    tag: "hardware",
    price: "10,000",
    img: ImgProduct003,
  },
  {
    href: "/product",
    itemName: "ITEM004",
    tag: "hardware",
    price: "10,000",
    img: ImgProduct004,
  },
];
