import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

export const ProductCard = (props) => {
  return (
    <SGridItem>
      <Link to={props.href}>
        <img src={props.img} alt="product" />
        <p>{props.itemName}</p>
        <p>
          <span>¥</span>
          {props.price}
          <span>（税込）</span>
        </p>
      </Link>
    </SGridItem>
  );
};

const SGridItem = styled.div`
  a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    transition: all 0.2s;
    img {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 3/4;
    }
    p {
      font-size: 0.9rem;
      line-height: 1;
      &:first-of-type {
        font-weight: 700;
        margin-top: 1rem;
      }
      &:last-of-type {
        font-weight: 500;
        margin-top: 0.5rem;
        span {
          &:first-of-type {
            margin-right: 4px;
          }
          &:last-of-type {
            margin-left: 4px;
            font-size: 0.6rem;
          }
        }
      }
    }
    /* hover */
    @media screen and (min-width: 1025px) {
      &:hover {
        opacity: 0.7;
      }
    }
  }
  /* tb */
  @media screen and (max-width: 1023px) {
    a {
      img {
        aspect-ratio: 1/1;
      }
      &:active {
        opacity: 0.7;
      }
    }
  }
`;
