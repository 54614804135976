import ImgProduct003 from "../images/product_003.jpg";
import ImgProduct004 from "../images/product_004.jpg";
import ImgProduct005 from "../images/product_005.jpg";
import ImgProduct006 from "../images/product_006.jpg";

export const NewList = [
  {
    href: "/product",
    itemName: "ITEM003",
    tag: "hardware",
    price: "10,000",
    img: ImgProduct003,
  },
  {
    href: "/product",
    itemName: "ITEM004",
    tag: "hardware",
    price: "10,000",
    img: ImgProduct004,
  },
  {
    href: "/product",
    itemName: "ITEM005",
    tag: "hardware",
    price: "10,000",
    img: ImgProduct005,
  },
  {
    href: "/product",
    itemName: "ITEM006",
    tag: "hardware",
    price: "10,000",
    img: ImgProduct006,
  },
];
