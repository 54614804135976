import React from "react";

import { DefaultLayout } from "../components/layout/defaultLayout";
import { PickupWrapper } from "../components/wrapper/pickupWrapper";
import { NewWrapper } from "../components/wrapper/newWrapper";
import { CategoryWrapper } from "../components/wrapper/categoryWrapper";

const HomePage = () => {
  return (
    <DefaultLayout pageTitle="ホーム" headerTitle="HOME">
      <PickupWrapper />
      <NewWrapper />
      <CategoryWrapper />
    </DefaultLayout>
  );
};

export default HomePage;
